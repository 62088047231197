*{padding: 0; margin: 0; border: 0;
    box-sizing: border-box;}
    body {
        color: black;
         height: 100vh;
         background: #EBF2F5;
      
    }
    :root {
        font-size: 62.5%
    }
    body, input, textarea, button {
        font-size: 1.8rem;
        line-height: 1;
        font-weight: 400;
        font-family: 'nunito', sans-serif;
    }


    
    