.switch-mode-consul {
	display: block;
	min-width: 50px;
	height: 25px;
	border-radius: 25px;
	background: var(--grey);
	cursor: pointer;
	position: relative;
}
.switch-mode-consul::before {
	content: '';
	position: absolute;
	top: 2px;
	left: 2px;
	bottom: 2px;
	width: calc(25px - 4px);
	background: var(--blue);
	border-radius: 50%;
	transition: all .3s ease;
}
 #switch-mode-consul:checked + .switch-mode-consul::before {
	left: calc(100% - (25px - 4px) - 2px);
}
td span{
	margin-left: 10px;
}