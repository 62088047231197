@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;500;600;700&display=swap');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}
body {
    font-family: Nunito sans, sans-serif;
}

a {
	text-decoration: none;
}

li {
	list-style: none;
}

:root {
	--poppins: 'Poppins', sans-serif;
	--lato: 'Lato', sans-serif;
	--vermelha:#ed2f59;
	--light: #F9F9F9;
	--blue: #3C91E6;
	--light-blue: #CFE8FF;
	--grey: #eee;
	--dark-grey: #AAAAAA;
	--dark: #342E37;
	--red: #DB504A;
	--yellow: #FFCE26;
	--light-yellow: #FFF2C6;
	--orange: #FD7238;
	--light-orange: #FFE0D3;
    --institution: rgb(106, 106, 154);
}

html {
	overflow-x: hidden;
}



body {
	
	background: var(--grey);
	overflow-x: hidden;
}



/* NAVBAR */

.capa-home{
	position:relative;
	width: 100%;
    height:100ch;
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    background : url('../../assets/images/nathalie_capa2.jpeg');
    background-repeat:no-repeat;
    background-size :cover;
    background-position: center;
	z-index: 1000;
	
	
	
}
.capa-home::before{
	content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0,0,0,0.7);

}

.capa-home h1 { 
	opacity: 1;
    text-align: center;
    font-size: 100px;
    text-shadow: 0 0 10px #000;
    color: #fff;
    margin: 10px 0 0 0;
    font-family: Merienda one, sans-serif;
	animation-delay: 150ms;	
	
}
.sous-titre {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    color: #f1f1f1;
    margin: 5px 0;
    text-shadow: 0 0 10px #000;
}









.bloc-sobre {
    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    
}
.bloc-sobre .content{
    flex: 1 1 45rem;
    padding: 15px;
    margin-right: 20rem;
}
.bloc-sobre .content h3{
    color:var(--institution) ;
    font-size: 4rem;
    padding: .5rem 0;
    font: 800 clamp(2rem, 5vw, 4rem)/1.1 'nunito',sans-serif;
    text-align: center;
}
.bloc-sobre .content p{
    color:var(--dark) ;
   
    padding: .5rem 0;
    line-height: 2;
    margin-top: 2.4rem;
    font: 400 1.6rem/1.5 'nunito', sans-serif;
    text-align: justify;
    
}
.bloc-sobre .content .icons-container{
display: flex;
gap: 1rem;
flex-wrap: wrap;
padding: 1rem 0;
margin-top: .5rem;
}
.bloc-sobre .content .icons-container .icons{
background: #eee;
border-radius: .5rem;
border:.1rem solid rgba(0,0,0,.2);
display: flex;
align-items: center;
justify-content: center;
gap: 1rem;
flex: 1 1 17rem;
padding: 1.5rem 1rem
}
.bloc-sobre .content  .icons-container .icons i{
    font-size: 2.5rem;
    color: var(--green);
}
.bloc-sobre .content .icons-container .icons span{
    font-size: 1.5rem;
    color: var(--institution);
}
.bloc-sobre .content .icons-container .icons a{
    text-decoration: none;
}
.bloc-sobre .image-nat{
   flex: 1 1 45rem;
}
.bloc-sobre .image-nat img{
   
    object-fit: cover;
    width: 50%;
    margin-left: 30%;
}
.bloc-txt-left {
    width: 50%;
    height: 100%;
}

.bloc-txt-left h3 {
    width: 150%!important;
    font-size: 35px;
    margin: 15px 0;
    padding: 15px;
}
.bloc-txt-left p {
    line-height: 40px;
    padding: 15px;
}
.btn-bloc-left {
    border-radius: 9999px;
    outline: 0;
    border: 0;
	background: rgb(106, 106, 154);
    border: 1px solid #333;
    color: #f1f1f1;
    padding: 20px 35px;
    font-size: 20px;
    display: flex;
 margin: auto;
    cursor: pointer;
}




@media screen and (max-width: 1550px) {

    .section-asymetrique {
        grid-template: 200px repeat(26, 100px) / 1fr 400px 1fr;
    }

    .bloc-1 {
        grid-area: 3 / 2 / 9 / 3;
    }
    .bloc-left {
        left: -150px;
    }
    .bloc-txt-left {
        width: 70%;
    }
    .bloc-txt-left p {
        line-height: 30px;
        padding: 15px;
    }
    .btn-bloc-left {
        padding: 15px 30px;
        font-size: 18px;
        margin: 10px;
    }

    /* Bloc-2 */

    
    .bloc-txt-2 button {
        padding: 15px 30px;
        font-size: 18px;
        margin: 10px;
    }

    


   

}


@media screen and (max-width: 850px) {

   

    
}


@media screen and (max-width: 430px) {
    
}


/*-------- Section Parallax ----------*/

.parallax {
    width: 100%;
    height: 300px;
    background: url(ressources/parallax-large-flou.jpg);
    background-position: center;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
    padding: 5rem;
}
.parallax p {
    font-size: 65px;
    color: #f1f1f1;
}
.parallax h2{
    color:#f1f1f1;
    margin-bottom: 4rem;
}
.parallax span {
    display: block;
    font-size: 25px;
    color: #f1f1f1;
    letter-spacing: normal;
}

@media screen and (max-width: 400px) {
    .parallax p {
        font-size: 55px;
    }
}

/* ------------ Section Tarifs ------------ */

.section-tarifs {
    padding-bottom: 150px;
    border-bottom: 1px dashed #333;
}
.section-tarifs h3 {
    text-align: center;
    font-size: 45px;
    margin: 120px 0 20px;
    padding: 0 30px;
}
.section-tarifs hr {
    width: 200px;
    margin: 20px auto 100px;
}

.container-tarifs {
	position: absolute;
    display: flex;
	width: 100%;
    justify-content: center;
    flex-direction: row;
	bottom: 0;
	z-index: 2000;
	left: 0;
	
}
.cartes-tarifs {
    width: 250px;
    height: auto;
    border-radius: 10px;
    margin: 15px 15px;
    cursor: pointer;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
	background: var(--light);
    transition: all 0.2s ease-in-out;
    position: relative;
}
.cartes-tarifs:hover {
    transform: translateY(-5px)
}

.cartes-tarifs h4 {
    text-align: center;
    padding: 25px 20px;
    font-size: 18px;
    clip-path: polygon(0 0, 100% 0%, 100% 80%, 0 100%);
    background:  rgb(106, 106, 154);
	color: white;
}
.cartes-tarifs span {
    display: block;
    text-align: center;
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
}


.cartes-tarifs p {
    font-size: 18px;
    padding: 20px 10px;
    border-top: 1px solid rgb(194,197,194);
}
.cartes-tarifs p:last-of-type{
    border-bottom: 1px solid rgb(194,197,194);
}
.cartes-tarifs p:nth-child(odd) {
    background: rgba(195,195,195,0.219);
}

.cartes-tarifs a {
    display: block;
    text-decoration: none;
    border: 1px solid #333;
    text-align: center;
    width: 150px;
    height: 50px;
    border-radius: 9999px;
    color: #333;
    font-size: 14px;
    line-height: 50px;
    margin: 20px auto;
    transition: all 0.2s ease-in-out;
}
.cartes-tarifs a:hover {
    background: #333;
    color: #f1f1f1;
}
@media screen  and (max-width:600px) {

    

    .parallax span {
        font-size: 1.2rem;
    }
    .parallax h2 {
        font-size: 2rem;
        text-align: center;
    }
    #contact h2{
        font-size: 3rem;
    }
    
	.container-tarifs {     
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        overflow-x: scroll;
        scroll-behavior: smooth;
        width: 100%;            
	}
    .bloc-sobre {
    
        display: block;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
       

        
    }

    .bloc-sobre img{
        display: none;
    }
    .bloc-sobre .content{
        
        width: 100%;
    }
    .capa-home h1{
        
        font-size: 3rem;
    }
    .capa-home > div{
margin-bottom: -600px;
    }
	.cartes-tarifs {
        max-width:150px;
		height: auto;
		border-radius: 10px;
		margin: 15px 15px;
        
		cursor: pointer;
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
		background: var(--light);
		transition: all 0.2s ease-in-out;
		position: relative;
        scroll-snap-align: start;
        scroll-snap-stop: always;

        
	}
   
    .cartes-tarifs h4 {
        text-align: center;
        padding: 25px 20px;
        font-size: 12px;
        clip-path: polygon(0 0, 100% 0%, 100% 80%, 0 100%);
        background:  rgb(106, 106, 154);
        color: white;
        font-weight: 500;
    }
    .cartes-tarifs span {
        display: block;
        text-align: center;
        padding: 7px;
        font-size: 12px;
       
    }
    
    
    .cartes-tarifs p {
        font-size: 12px;
        padding: 10px 5px;
        border-top: 1px solid rgb(194,197,194);
    }
    
    
    .cartes-tarifs a {
        display: block;
        text-decoration: none;
        border: 1px solid #333;
        text-align: center;
        width: 100px;
        height: 20px;
        border-radius: 9999px;
        color: #333;
        font-size: 9px;
        line-height: 20px;
        margin: 5px auto;
        transition: all 0.2s ease-in-out;
    }
    .cartes-tarifs a:hover {
        background: #333;
        color: #f1f1f1;
    }
	
}



/* ------- Section Contact ---------- */

.section-contact {
    padding-bottom: 100px;
}
.section-contact h2 {
    text-align: center;
    color: #333;
    font-size: 48px;
    font-weight: 400;
    margin-bottom: 50px;
    padding: 70px 0 0 0;
}

.container-form {
    margin: 0 auto;
    width: 70%;
}
.form-groupe {
    position: relative;
    margin-bottom: 45px;
}
.form-groupe label {
    color: #aaa;
    font-size: 20px;
}
.form-groupe input {
    display: block;
    width: 100%;
    padding: 10px 0px;
    border: none;
    outline: none;
    background: none;
    border-bottom: 3px solid #aaa;
    color: #666;
    font-size: 20px;
}

.form-groupe textarea {
    width: 100%;
    height: 200px;
    border: none;
    border: 3px solid #aaa;
    border-radius: 3px;
    padding: 5px;
    resize: none;
    outline: none;
    font-size: 20px;
    background: #f1f1f1;
}

.form-groupe:nth-child(4) {
    margin-bottom: 25px;
}

.form-groupe .button-sub {
    display: block;
    width: auto;
    padding: 15px 60px;
    border: 3px solid rgb(144,144,144);
    border-radius: 9999px;
    background-image: linear-gradient(to right, transparent 50%, rgb(65,65,65) 50%, rgb(134,134,134));
    background-size: 200%;
    color: #333;
    font-size: 24px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
}
.form-groupe .button-sub:hover {
    color: #fff;
    background-position: 100%;
    border: 3px solid #fff;
}

@media screen and (max-width: 1300px) {
    .section-contact h2 {
        padding: 70px 0 0 0;
    }
    .container-form {
        padding-left: 0;
    }
}


/*------------ Footer ------------ */

footer {
    border-top: 1px solid #333;
    width: 100%;
    text-align: center;
    padding: 10px 0;
}







