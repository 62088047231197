.boxLogin-cadastro {
    position: absolute;
    display: flex;
    width: 600px;
    height: auto;
    flex-shrink: 0;
    display: flex;
    background: #ffffff;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-left: -300px;
    margin-top: -300px;
    top: 50%;
    left: 50%;
}

.containerLogo {
    height: 43%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.containerInputs legend {
    font-size: 2.2rem;
}

.containerInputs .AlertSucess {
    background-color: #d1e7dd;
    color: #0f5132;
    margin: 20px 0;
    border: 1px solid #badbcc;
    border-radius: 4px;
    padding: 7px;
}

.containerInputs .AlertDanger {
    background-color: #f8d7da;
    color: #842029;
    margin: 20px 0;
    border: 1px solid #badbcc;
    border-radius: 4px;
    padding: 7px;
}

.cercle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: rgb(106, 106, 154);
    display: flex;
    justify-content: center;
    align-items: center;
}

.cercle img {
    width: 150px;
}

form button {
    display: block;
    margin: 20px auto;
    outline: 0;
    background: rgb(106, 106, 154);
    width: 90%;
    border: 0;
    padding: 15px;
    border-radius: 3px;
    box-sizing: border-box;
    font-size: 16px;
    cursor: pointer;


}

.txtFin {
    text-align: center;
    padding: 15px;
}

.txtFin a {
    text-decoration: none;
    color: salmon;
}

.flex2Inputs {
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin: 0 auto;

}

.flexInp {
    margin: 20px auto;
    outline: 0;
    background: #f1f1f1;
    width: 90%;
    border: 0;
    padding: 15px;
    font-size: 14px;
    border-radius: 3px;

}

.flexLeftInp,
.flexRightInp {
    flex-basis: 45%;

}

.labelFlex {
    color: #555;
    position: relative;
    top: 8px;


}

.emailInp,
select,
textarea {
    display: block;
    margin: 20px auto;
    outline: 0;
    background: #f1f1f1;
    width: 95%;
    border: 0;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
    border-radius: 3px;
}

.labelEmail,
.labelSelect,
.labelTxt {
    color: #555;
    display: block;
    margin: 0 auto;
    width: 95%;
    position: relative;
    top: 7px;
}

.btn-cadastrar {
    width: 45%;
    display: block;
    margin: 45px auto 0 auto;
    outline: none;
    border: 0;
    height: 50px;
    transition: all 0.2s linear;
    cursor: pointer;
    border-radius: 5px;
    font-size: 17px;
    color: #f1f1f1;
}

button:hover {
    background: #ccc;
}

@media screen and (max-width: 600px) {
    .flex2Inputs {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 95%;
        margin: 0 auto;

    }

    .flexLeftInp,
    .flexRightInp {

        width: 300px;

    }


}