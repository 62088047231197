.home .home-slider{
    max-width: 100%;
    margin: 0 auto;
    position: relative;
}

.home .home-slider .slide{
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    padding-top: 9rem;
    height: 50rem;
}

.home .home-slider .slide .content{
    flex: 1 1 45rem;
}
.home .home-slider .slide .image{
    flex: 1 1 45rem;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
}
.home .home-slider .slide .image img{
   width: 100%;
   
}
.home .home-slider .slide .content span{
   color: var(--congolinaria);
   font-size: 2.5rem;
}
.home .home-slider .slide .content h3{
    color: var(--dark);
    font-size: 7rem;
 }
 .home .home-slider .slide .content p{
    color: var(--light-color);
    font-size: 2.2rem;
    padding: .5rem 0;
    line-height: 1.5;
 }

.review .slide{
    padding: 2rem;
    box-shadow: var(--box-shadow);
    border: 1rem solid rgba(0,0,0,.2);
    position: relative;
}
.review .slide .fa-quote-right{
    position: absolute;
    top: 2rem; right: 2rem;
    font-size: 6rem;
    color: #ccc;
}
.review .slide .user{
    display: flex;
    gap: 1.5rem;
    align-items: center;
    padding-bottom: 1.5rem;
}
.review .slide .user img{
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
    object-fit:cover;
}
.review .slide .user  h3{
    color:var(--dark);
    font-size: 2rem;
    padding-bottom: .5rem;
}
.review .slide .user i{
    font-size: 1.3rem;
    color: var(--congolinaria);
}
.review .slide p{
  font-size: 1.5rem;
  line-height: 1.8;  
  color: var(--light-color);
}
.navigation-button{
    position: absolute;
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    background-color: var(--dark);
    border: .2rem solid #d4d4d4;
    top:50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.navigation-button img{
    width: 1.5rem;
   
}
.prev-button-slider{
    left: -10rem;
}
.navigation-button:hover{
    background-color: var(--congolinaria);
}
.next-button-slider{
    right: -10rem;
}
@media (max-width:991px){
    .navigation-button{
        width: 3.5rem;
        height: 3.5rem;
    }
    .prev-button-slider{
        left: -7.5rem;
    }
    .next-button-slider{
        right: -7.5rem;
    }
    .navigation-button img{
        width: 1.2rem;
    }
    .home .home-slider .slide{
        height: 100%;
    }
}

@media(max-width:768px){
  
    .home .home-slider .slide .content h3{
        font-size: 5rem;
     }
     .home .home-slider .slide .content {
        /*position: absolute;*/
        background: rgba(0,0,0,.2);
        font-size: 5rem;
        bottom: 0;
        width: 100%;
        padding: 10px;
     }
     .home .home-slider .slide .content span{
        font-size: 2rem;
     }
     .home .home-slider .slide .content h3{
        font-size: 5rem;
     }
     .home .home-slider .slide .content p{
        font-size: 1.2rem;
        color: #000;
     }
     button.prev-button-slider{
        left: 1rem;
    }
    button.next-button-slider{
        right: 1rem;
    }
   
     
}

