.card{
   /* max-width: 300px;
    padding: 2rem;
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
    margin: 10px;
    cursor: pointer;
    min-height: 200px;
    min-width: 300px;
    text-align: left;
    flex-direction: column;
    justify-content: space-between;*/

    width: 300px;
    margin: 5rem;
    margin: 5px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    


}
.titre-post{
    margin: 1rem;
}
.carte-blog img{
    width: 100%;
    height: auto;
    max-width:100%;
    height:auto;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.card h2{
    font-size:1.5rem;
    margin-bottom: 10px;
}
.card p{
    font-size: 18px;
}

@media screen  and (max-width:600px){
    .card{
        display: block;
        width: 100%;

        
    }
    .carte-blog{
        
        width: 100%;
       
    }
    .carte-blog img{
        
        max-width:95%;
    height:auto;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    }
    
}