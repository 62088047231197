*,
*:before,
*:after{
padding: 0;
margin: 0;
border-radius: border-box;
}
.agenda input[type="date"]{
    width: 250px;
    font-family: "Roboto Mono",monospace;
    color: #000;
    font-size: 18px;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 15px;
    top:50%;
    left: 50%;
}
::-webkit-calendar-picker-indicador{
    background-color: #ffffff;
    padding: 5px;
    cursor: pointer;
    border-radius: 3px;
}

.agenda{
    margin-top: 150px;
    width: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top:50%;
    left: 50%;
    background-color: rgb(106, 106, 154);
    font-family: "Roboto Mono",monospace;
    position: absolute;
    padding: 15px;
    transform: translate(-50%, -50%);
    color: #ffffff;
    font-size: 18px;
    border: none;
    outline: none;
    border-radius: 5px;
    gap: 100px;
   
}

.container-horas {
    
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    

    
}
.container-horas .container {
	margin: 5px;
	background: #AAAAAA;
	text-align: center;
	font-size: 1.5em;
}
.horas{
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    width: 30rem;
    justify-content: center;
    align-items: center;
    
    
   
}


  /* Create a custom radio button */
.agenda-btn{
    display: flex;
    width: 100%;
    height: 3.6rem;
    border: 0;
    cursor: pointer;
    background : #f1f1f1;
    border-radius: 2rem;
    color: #666;
    display: flex;
    align-items:center;
    justify-content: center;
    max-width: 250px;
    font-size: 18px;

    transition: background-color .2s;
}
.agenda-btn:active{
    background: #edfff6;
    border: 1px solid #a1e9c5;
    color :#37c77f;
}
.agenda-btn:hover{
    background-color:#17d6eb;
    color:#fff;}