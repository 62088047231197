.container-login {
    position: fixed;
    height: 100%;
    font-family: Arial, Helvetica, sans-serif;
    min-height: 100vh;
    height: auto;
    padding: 32px 24px;
    background: #dddcdc;
   
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width:100vw;
    top: 0px;
    border-radius: 5px;
    z-index: 5000;
}
.close-btn{
    position: absolute;
    top:10px;
    right:10px;
    padding:5px 7px;
}
.boxLogin {
    position: relative;
    width: 350px;
    height: auto;
    flex-shrink: 0;
    background: #fff;
    border-radius: 5px;
    margin: auto;
    top: 50%;
    padding: 15px;
}

.containerLogo {
    height: 43%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
}
.cercle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: rgb(106,106,154);
    display: flex;
    justify-content: center;
    align-items: center;
}
.cercle img {
    width: 150px;
}

.containerInputs {
    height: 57%;
}

form input {
    display: block;
    margin: 20px auto;
    outline: 0;
    background: #f2f2f2;
    width: 90%;
    border: 0;
    padding: 15px;
    border-radius: 3px;
    box-sizing: border-box;
    font-size: 14px;
}

form button {
    display: block;
    margin: 20px auto;
    outline: 0;
    background: rgb(106,106,154);
    width: 90%;
    border: 0;
    padding: 15px;
    border-radius: 3px;
    box-sizing: border-box;
    font-size: 16px;
    cursor: pointer;
}

.txtFin {
    text-align: center;
    padding: 15px;
}
.txtFin a {
    text-decoration: none;
    color: salmon;
}