#page-unidades{
    width: 100%;
    height: 100%;
    display: flex;
}
aside{
    position: fixed;
    height: 100vh;
    width: clamp(24rem, 25vw, 44rem);
    background:  rgb(243, 244, 245); 
    padding: clamp(3rem, 5vw, 8rem);
    display: flex;
    flex-direction:column;
    justify-content: space-between;
    align-items: center;
   
}
aside h2 {
    margin-top: 6.4rem;
    font: 800 clamp(2rem, 5vw, 3.6rem)/1.1 'nunito',sans-serif;
}

aside p {
    margin-top: 2.4rem;
    font: 400 1.6rem/1.5 'nunito', sans-serif;
}
aside header {
    display: block;
}
aside footer p {
    margin-top: .8rem;
}
.Containerblog{
    width: 100%;
    
    display: flex;
    flex-direction: row;
    
    margin-top: 20px;
}

.sug-Blog{
    margin-top: 70px;
}
.sug-Blog a {
    text-decoration: none;
}
.sug-Blog h2{
    font: 400 1.6rem/1.5 'nunito', sans-serif;
    font-size: 22px;
    color: #ed2f59;


}
.contenair-categorie{
    max-width: 55vw;
    margin-left:30%;
    align-items: center;
   
}
.nav-categorie{
    position: relative;
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: 10px;   
}
.btn-left{
    position: absolute;
    left: 25%;
    top: 90px;
}
.btn-right{
    position: absolute;
    right: 10%;
    top: 90px;
}
.nav-categorie::-webkit-scrollbar{
    display: none;
}
.item-cat{
    flex: none;
    padding: 10px;

    
}
.item-cat a {
    text-decoration: none;
    background-color: #999;
    color: black;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    

}

button.create-unidade-congolinaria {
    position:absolute;
    right: 4rem;
    bottom: 4rem;
    z-index:10 ;
    color: white;

    width: 6.4rem;
    height: 6.4rem;
    background: #ed2f59 ;
    border: 0;
    border-radius:2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:background-color 200ms;
    cursor: pointer;
}
button.create-unidade-congolinaria:hover {
    background: #17d6eb ;
    cursor: pointer;
}

.container-blog{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:row;
    flex-wrap:wrap;
    margin-top: 60px;
}

.carte-blog {
    width: 300px;
    margin: 5rem;
    margin: 5px;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    
}
.carte-blog  img{
    width: 300px;
    height:150px;
    border-radius: 10px;
    
}
.carte-blog h2{
    color:#555;
    background-color: #f5f8fa;
    padding: 10px;
    font: 800 clamp(1.2rem, 5vw, 1.2rem)/1.1 'nunito',sans-serif;
    border-radius: 0 10px 10px 0;
}
.carte-blog, .carte-single-blog p {
    text-align: justify;
    color: #555;
    font: 400 1.6rem/1.5 'nunito', sans-serif;
}
.carte-blog a{
    text-decoration: none;

}

.titre-post{
    margin-top: 10px;
    color: black;
    font-size: 12px;
}
.img-vegan{
    position: absolute;
    top:10px;
    left:10px;
    border-radius: 5px;
    padding:3px 5px;
    color: white;
    background-color: #22bb88;
    font-size: 10px;
}
/*.carte {
    width: 400px;
    height: 300px;
   
    margin: 5px;
   
   
    

}

.carte img{
    width: 300px;
    height: 150px;
    border-radius: 10px;
    
}
.carte h2{
    color:#555;
    font: 800 clamp(1.6rem, 5vw, 2rem)/1.1 'nunito',sans-serif;
}
.carte p {
    text-align: justify;
    color: #555;
    font: 400 1.6rem/1.5 'nunito', sans-serif;
}
  /* .restaurante{
   
    height: initial;
    text-align: initial;
    text-align: justify;
    font-size:1.2rem ;
    line-height: 1.5;
    padding: 50;
    margin: auto;
    margin-top: 20px;
    display:  flex;
   
    margin-left: 250rem;
    
}
/*
.restaurante a {
    text-decoration: none;
    color: #555;
}
.imagem-restaurante{
    display:table-cell;
    float: left;
}
.conteudo-restaurante {
    display:table-cell;
    float: left;
    width: 500px;
    margin-left: 20px;
    overflow: hidden;
    margin-top: 10px;
}

.restaurante p{
    margin-top: 10px;
}
.restaurante h1{
    color: #555;
    text-align: left;
    font-size: 2rem;
}

*/
.btn {
    background: transparent;
    font-weight: 500;
    color: #ed2f59;
    margin: 5px;
}

@media screen and (max-width:600px) {
    .container-blog{
        width: 100%;
        margin-left: auto;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;    
        flex-wrap:wrap;
    }
    .sug-Blog{
        display:none;
    }
    .carte-blog{
     width: 150px;
     height: auto;
     display: flex;
     flex-direction: column;
     align-items: flex-start;
     justify-content: center;
     margin-bottom:5px;
     margin-top: 0px;
 
    }
    .carte-blog img{
        margin-top: 0px;
        width: 100%; 
        height: 75px;       
    }
    .carte-blog p{
        font-size: 1.2rem;
        color: #000;
        padding: 10px;
        text-align: justify; 
    }
    .carte-blog h2{
        text-align: center;
        margin: 10px 0 0 0;
        color: #555;
        font-size: 12px;
        
      
   
    }
    .carte-blog a{
        text-decoration: none;

    }
    .img-vegan{
        font-size: 6px;
    }
    button.create-unidade-congolinaria{
        display: none;
    }
    
    .animate-right{
        display: none;
        width: 0px;
    }

    .container-single-blog{
        width: 95%;
        margin-left: auto;
        padding: 5rem;
    }
    .contenair-categorie{
        max-width: 100vw;
    }
}
