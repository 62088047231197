* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}
body {
    font-family: Nunito sans, sans-serif;
}

.body-post{
   
    width: 700px;
    height: auto;
    margin-top: 60px;
    background: white;
    line-height: 1.5;
    padding: 2.5rem;
    font: 1em arial;
    
    margin-bottom: 100px;
}

.images-body{
    /*display: flex;
    margin-left: 60px;
    flex-direction:column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 100px;*/

    position:relative;
	width: 100%;
    /*height:100ch;*/
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    background-repeat:no-repeat;
    background-size :cover;
    background-position: center;
	z-index: 1000;
    top:100px;
   
}

.images-body h2{
    font-size: 3rem;
    width: 700px;
    font: 800 clamp(2rem, 5vw, 3rem)/1.1 'nunito',sans-serif;
}
.images-body img{
 width: 700px;
 
}
/*------------ Footer ------------ */

.rodape{
    
    border-top: 1px solid #333;
    width: 100%;
    text-align: center;
    bottom: 0;
    
    
}

@media screen  and (max-width:600px) {
    .images-body{
        padding: 5px;
        
        
    }
    .images-body h2{
        width: 100%;
    }

    .body-post{
        width: 100%;
        padding: 10px;
        padding: auto;
        margin: 10px;
    }
}