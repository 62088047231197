.founds{
    margin-top: 150px;
}
.no-founds {
    position:absolute;
    right: 4rem;
    bottom: 4rem;
    z-index:10 ;
    color: white;

    width: 6.4rem;
    height: 6.4rem;
    background: rgb(106, 106, 154); 
    border: 0;
    border-radius:2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:background-color 200ms;
    cursor: pointer;
}
.no-founds:hover {
    background: #17d6eb ;
    cursor: pointer;
}
h1{
    
    text-align: center;
}