
@keyframes up{
    from{
        opacity: 0;
        transform: scale(1);
    }
    to{
        opacity: 1;
        transform: scale(2.5);
    }
}
@keyframes right {
    form{
transform:translateX(-100%);
    }
    to{
        transform:translateX(0);
    }
}

@keyframes appear{
    from{
        opacity: 0;

    }
    to{
opacity: 1;
    }
}
.animate-up{
    animation-name:up;
    animation-duration: 500ms;
    animation-fill-mode: backwards;
}
.animate-right{
    animation-name: right;
    animation-duration: 300ms;
}
.animate-appear {
    animation-name:appear;
    animation-duration: 300ms;
    animation-fill-mode: backwards;
}