
.primary-button{
    margin-top: 6.4rem;

    width: 100%;
    height: 6.4rem;
    border: 0;
    cursor: pointer;
    background : #3cdc8c;
    border-radius: 2rem;
    color: white;
    display: flex;
    align-items:center;
    justify-content: center;

    transition: background-color .2s;
}

.primary-button{
    background: #36cf82
}
.primary-button img{
    margin-right: 1.6rem;
}
.button-select {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.button-select button{
    height: 6.4rem;
    background:#f5f8fa;
    border: 1px solid #d3e2e5;
    color: #5c8599;
    cursor: pointer;
}

.button-select button.active{
    background: #edfff6;
    border: 1px solid #a1e9c5;
    color :#37c77f;
}
.button-select button:first-child{
    border-radius:2rem 0 0 2rem;
}
.button-select button:last-child{
    border-radius: 0 2rem 2rem 0;
}